import React from 'react';

const Loader = () => (
  <div className="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default Loader;